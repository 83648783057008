<template>
	<v-col cols="auto">
		<v-hover v-slot:default="{ hover }" open-delay="200">
			<base-card max-height="480px" max-width="280px" color="grey lighten-1 backImage" dark>
				<v-img :src="product.imageUrl" gradient="rgba(0, 0, 0, .42), rgba(0, 0, 0, .42)">
					<template v-slot:placeholder>
						<v-row class="fill-height ma-0" justify="center">
							<v-progress-linear indeterminate color="grey lighten-5"></v-progress-linear>
						</v-row>
					</template>
					<v-row v-if="!product.prominent" class="text-center ma-0">
						<v-chip class="mx-2 ma-2" color="red darken-3" text-color="white" @click.stop="">
							{{ product.price }}
						</v-chip>

						<v-col class="pt-15" cols="12">
							<h3 class="title font-weight-bold font">
								{{ product.title }}
							</h3>
							<div class="md-2 font">
								{{ product.shortDescription }}
								<modal :dialogVisible="dialogVisible" @close="dialogClose" :id="id" />
								<!-- <modal /> -->
							</div>
						</v-col>
					</v-row>
				</v-img>
				<v-fade-transition>
					<v-overlay align="center" v-if="hover" absolute color="black">
						<v-btn rounded small class="btnfos" color="green darken" title="Dodaj do koszyka" :href="product.sellingLink">
							<v-icon left> mdi-cart-plus </v-icon>
							<span>Dodaj do koszyka</span>
						</v-btn>
						<v-btn @click="showDialog" rounded title="opis" color="orange" small outlined>
							<v-icon left> mdi-book-open-page-variant</v-icon>
							<span>Opis e-booka</span>
						</v-btn>
					</v-overlay>
				</v-fade-transition>
			</base-card>
		</v-hover>
	</v-col>
</template>

<script>
export default {
	name: "FeedCard",
	data: () => ({
		dialogVisible: false,
	}),
	components: {
		Modal: () => import("@/components/Modal"),
	},
	props: {
		id: String,
	},
	computed: {
		product() {
			return this.$store.getters.product(this.id);
		},
	},
	methods: {
		dialogClose() {
			this.dialogVisible = false;
		},
		showDialog() {
			this.dialogVisible = true;
		},
	},
};
</script>

<style lang="scss">
.font {
	font-family: "GloriaHallelujah" !important;
	font-size: 15px;
	font-style: italic;
}
.title {
	font-size: 20px !important;
	font-weight: bold !important;
}

.btnfos {
	color: rgb(255, 255, 255);
	cursor: pointer;
	font-size: 16px;
	font-weight: 400;
	line-height: 45px;
	margin: 0 auto 2em;
	text-transform: uppercase;

	& .btnfos-5 {
		border: 0 solid;
		box-shadow: inset 0 0 20px rgba(50, 156, 1, 0.884);
		outline: 1px solid;
		outline-color: rgba(52, 255, 1, 0.219);
		outline-offset: 0px;
		text-shadow: none;
		-webkit-transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
		transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
		outline-color: rgba(0, 255, 42, 0.185);
		outline-offset: 0px;

		&:hover {
			border: 1px solid;
			box-shadow: inset 0 0 20px rgba(231, 235, 6, 0.719), 0 0 20px rgba(245, 168, 2, 0.589);
			outline-offset: 15px;
			outline-color: rgba(51, 43, 17, 0);
			text-shadow: 1px 1px 2px #427388;
			max & i.v-icon {
				&.v-icon {
					outline-color: black;
				}
			}
		}
	}
}

.backImage {
	transition: all 0.3s ease-in-out !important;

	&:hover {
		transform: translateY(-0.25em);
	}
}
</style>>

